<template>
  <!--
    Lists information about and enables camera-testing.
  -->
  <div class="cameraTester">
    <LoadingPlaceholder v-if="loading" />

    <template v-else>
      <template v-if="cameras">
        <div class="row">
          <Portlet
            :title="$t('cameraTesterComponent.startTest')"
            icon="film"
            class="col-12 col-lg-4"
          >
            <div slot="buttons">
              <button
                class="btn btn-sm btn-primary float-right"
                @click="openSidebar()"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="plus"
                />
                <span>{{ $t('add') }}</span>
              </button>
            </div>
            <Sidebar
              v-if="showSidebar"
              :show-sidebar="showSidebar"
              :sidebar-width="600"
              @close="closeSidebar"
            >
              <CameraTesterConfigure
                :installation-id="installationId"
                :lane-number="laneNumber"
                @reload="reloadOrders"
              />
            </Sidebar>
            <!-- table cpntainer raus-->
            <div> 
              <table class="defaultTable lastElementNoBorder">
                <colgroup>
                  <col>
                  <col>
                  <col>
                  <col width="75px">
                </colgroup>
                <thead>
                  <tr>
                    <th>{{ $t('cameraTesterComponent.camera') }}</th>
                    <th>{{ $t('created') }}</th>
                    <th>{{ $t('cameraTesterComponent.status') }}</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody
                  ref="scrollDiv"
                  class="scrollDiv"
                >
                  <template v-if="orders">
                    <tr
                      v-for="(order, index) in orders"
                      :key="`cameraTester-ordersListItem-${ index }`"
                    >
                      <td><span>{{ getCamera(order.networkCameraId) }} (Id: {{ order.networkCameraId }})</span></td>
                      <td v-tooltip="dateTime_dateTime(order.createDate)">
                        {{ dateTime_fromNowWithoutFuture(order.createDate) }}
                      </td>
                      <td>{{ getOrderStatus(order) }}</td>
                      <td>
                        <button
                          :disabled="order.orderStatus != 'finished'"
                          class="btn btn-sm btn-primary"
                          @click="createReportInterval(order.reportId)"
                        >
                          {{ $t('show') }}
                        </button>
                      </td>
                    </tr>
                  </template>
                  <tr v-else>
                    <td colspan="7">
                      <p>{{ $t('noDataAvailable') }}</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Portlet>
        

          <Portlet
            v-if="getResponse"
            :title="$t('cameraTesterComponent.testResults')"
            :sub-title="getSubTitle()"
            icon="chart-area"
            class="col-12 col-lg-8"
          >
            <LoadingPlaceholder v-if="gettingReport" />
            <template v-else>             
              <div
                class="row"
              >
                <!-- Motion Detection Report Order Charts start -->
                <div
                  v-if="motionSeries"
                  class="col-12 col-md-6"
                >
                  <h3 class="p-0 m-0">
                    {{ $t('motionDetectionReport') }}
                  </h3>
                  <hr class="p-0 m-0">

                  <div class="miniChart">
                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="motionCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'area'"
                      :series-defaults-style="'smooth'"
                      :value-axis="percentAxis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="motionPercent"
                    />

                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="motionCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'line'"
                      :series-defaults-style="'smooth'"
                      :value-axis="axis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="motionSeries"
                    />

                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="motionCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'area'"
                      :series-defaults-style="'smooth'"
                      :value-axis="motionBytesAxis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="motionBytesSeries"
                    />
                  </div>
                </div>
                <!-- Motion Detection Report Order Charts end -->

                <!-- Recording Report Order Charts start -->
                <div
                  v-if="recordingSeries"
                  class="col-12 col-md-6"
                >
                  <h3 class="p-0 m-0">
                    {{ $t('recordingReport') }}
                  </h3>
                  <hr class="p-0 m-0">

                  <div class="miniChart">
                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="recordingCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'area'"
                      :series-defaults-style="'smooth'"
                      :value-axis="percentAxis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="recordingPercent"
                    />

                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="motionCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'line'"
                      :series-defaults-style="'smooth'"
                      :value-axis="axis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="recordingSeries"
                    />

                    <kendo-chart
                      ref="chart"
                      :category-axis-categories="motionCategories"
                      :legend-position="'top'"
                      :chart-area-background="''"
                      :series-defaults-type="'area'"
                      :series-defaults-style="'smooth'"
                      :value-axis="recordingBytesAxis"
                      :value-axis-axis-crossing-value="'-10'"
                      :category-axis-major-grid-lines-visible="false"
                      :category-axis-labels-rotation="'auto'"
                      :theme="'sass'"
                      :series="recordingBytesSeries"
                    />
                  </div>
                </div>
              <!-- Recording Report Order Charts end -->
              </div> 
            </template>
          </Portlet>
        </div>
      </template>

      <p
        v-else
        class="mt-3"
      >
        {{ $t('noDataAvailable') }}
      </p>
    </template>
  </div>
</template>

<script>
import numberConversionAdapter from '@/adapter/numberConversionAdapter.js';

import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';
import { responsiveKendoGridMixin } from '@/mixins/responsiveKendoGridMixin.js';

export default {
  name: 'CameraTester',
  components: {
    CameraTesterConfigure: () => import('@/components/CameraManagement/CameraTesterConfigure.vue'),
    Sidebar: () => import('@/components/Base/Sidebar.vue'),
  },
  mixins: [
    dateTimeMixin,
    numberConversionAdapter,
    responsiveKendoGridMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      gettingReport: false,
      showSidebar: false,
      orders: null,
      cameras: null,
      reportInterval: null,
      orderInterval: null,
      getResponse: null,
      cancelGetSource: null,
      cancelOrderSource: null,
      motionCategories: null,
      recordingCategories: null,
      motionSeries: null,
      recordingSeries: null,
      motionBytesSeries: null,
      recordingBytesSeries: null,
      motionPercent: null,
      recordingPercent: null,
      motionFrameReportTypes: null,
      recordingFrameReportTypes: null,
      recordingFrameReportTypesData: null,
      motionFrameReportTypesData: null,
      guId: null,

      colors: ['red', 'blue', 'yellow', 'green', 'purple', 'pink'],

      axis: [
      {
        name: "Count",
        min: 0,
        max: 30,
        labels: {
          format: "{0} frames",
          position: 'start'
        }
      }],

      percentAxis: [{
        name: "Percent",
        min: 0,
        max: 100,
        labels: {
          format: "{0} %",
          position: 'start'
        }
      }
    ],

      motionBytesAxis: [
      {
        name: "KiloBytes",
        labels: {
          format: "{0} kb",
          position: 'start'
        }
      }],

      recordingBytesAxis: [
      {
        name: "KiloBytes",
        labels: {
          format: "{0} kb",
          position: 'start'
        }
      }]
    }
  },
  created () {
    this.getCameras();
    this.createOrderInterval();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy () {
    this.clearReportInterval();
    this.clearOrderInterval();
    this.cancelGetRequest();
    this.cancelOrderRequest();
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getSubTitle () {
      return `${this.$t('cameraTesterComponent.testType')}: ${this.getResponse.info.testType}, ${this.$t('cameraTesterComponent.testDuration')}: ${this.getResponse.info.testDuration}s, ${this.$t('cameraTesterComponent.fps')}: ${this.getResponse.info.fps}`;
    }, 
    getOrderStatus (order) {
       if(order.orderStatus == "running" && order.percentageDone)
       {
          return `${order.orderStatus} (${Math.round(order.percentageDone)}%)`;
       }
       return `${order.orderStatus}`;
    }, 
    openSidebar () {
      this.showSidebar = true;
    },  
    closeSidebar () {
      this.showSidebar = false;
    },
    handleResize () {
      this.responsiveKendoGridMixin_refresh(this.$refs.chart);
    },
    getCamera (cameraId) {
      let camera = this.cameras.find(x => x.id === cameraId);
      if (camera)
      {
        return camera.cameraType;
      }
      return 'Inactive Camera';
    },
    reloadOrders () {
      this.showSidebar = false;
      this.getOrders();
    },
//#region Create and Clear Intervals
    createReportInterval (guid) {
      if (guid) {
        this.guId = guid;
      }
      if (this.reportInterval != null) {
        this.clearReportInterval();
      }
      this.gettingReport = true;
      this.getStreamReportOrder();
      this.reportInterval = window.setInterval(() => {
        this.getStreamReportOrder();
      }, 1000);
    },
    createOrderInterval () {
      if (this.orderInterval != null) {
        this.clearOrderInterval();
      }
      this.getOrders();
      this.orderInterval = window.setInterval(() => {
        this.getOrders();
      }, 5000);
    },
    clearReportInterval () {
      window.clearInterval(this.reportInterval);
      this.reportInterval = null;
      this.gettingReport = false;
    },
    clearOrderInterval () {
      window.clearInterval(this.orderInterval);
      this.orderInterval = null;
    },
//#endregion

//#region Axios Requests
    getCameras () {
      let url = `/CameraManagement/GetAllCameras?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((cameraResponse) => {
          if (cameraResponse == null) {
            return;
          }
          if (cameraResponse.data == null) {
            return;
          }
          if (cameraResponse.data.length == 0) {
            return;
          }
          this.cameras = cameraResponse.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getOrders () {
      this.cancelOrderRequest();
      this.cancelOrderSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelOrderSource.token };
      let url = `/CameraManagement/GetAllOrders?installationId=${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((ordersResponse) => {
          if (ordersResponse == null) {
            return;
          }
          if (ordersResponse.data == null) {
            return;
          }
            this.orders = ordersResponse.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
        });
    },
    getStreamReportOrder () {
      this.cancelGetRequest();
      this.cancelGetSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelGetSource.token };
      let url = `/CameraManagement/GetStreamReportOrder?installationId=${ this.installationId }&guId=${ this.guId }`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          this.cancelGetSource = null;
          if (response.data == null) {
            return;
          }
          if (response.data.info == null) {
            return;
          }
          if (response.data.info.orderStatus == null) {
            return;
          }
          if (response.data.info.orderStatus.toLowerCase() == 'finished') {
            if (response.data.reports == null) {
              this.clearReportInterval();
              return;
            }
            this.getResponse = response.data;
            this.handleResponse(response.data);
            this.getOrders();
            this.clearReportInterval();
          }
        });
    },
//#endregion

//#region Handle GetStreamReportOrder Response
    handleResponse (response) {
      this.motionCategories = response.reports.motionDetectionReports.map(o => o.timestamp);
      this.recordingCategories = response.reports.recordingReports.map(o => o.timestamp);

      if (this.motionCategories.length == 0 || this.recordingCategories.length == 0) {
        this.getResponse = null;
        return;
      }

      let temporaryMotionDytesSeriesData = this.numberConversionAdapter_bytes(response.reports.motionDetectionReports.map(o => o.bytesReceived), true, 2);
      this.motionBytesAxis = [
      {
        name: `${ temporaryMotionDytesSeriesData[0].unit }Motion`,
        labels: {
          format: `{0} ${ temporaryMotionDytesSeriesData[0].unit }`,
          position: 'start'
        }
      }];
      this.motionBytesSeries = [
        {
          data: temporaryMotionDytesSeriesData.map(o => o.bytes),
          color: "#73c100",
          line: {
            style: "smooth"
          },
          opacity: .666,
          axis: `${ temporaryMotionDytesSeriesData[0].unit }Motion`,
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # ${ temporaryMotionDytesSeriesData[0].unit }`
          },
          name: `${ this.$t('cameraTesterComponent.received') }`
        }
      ];

      let temporaryRecordingBytesSeriesData = this.numberConversionAdapter_bytes(response.reports.recordingReports.map(o => o.bytesReceived), true, 2);
      this.recordingBytesAxis = [
      {
        name: `${ temporaryRecordingBytesSeriesData[0].unit }Recording`,
        labels: {
          format: `{0} ${ temporaryRecordingBytesSeriesData[0].unit }`,
          position: 'start'
        }
      }];
      this.recordingBytesSeries = [
        {
          data: temporaryRecordingBytesSeriesData.map(o => o.bytes),
          color: "#73c100",
          line: {
            style: "smooth"
          },
          opacity: .666,
          axis: `${ temporaryRecordingBytesSeriesData[0].unit }Recording`,
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # ${ temporaryRecordingBytesSeriesData[0].unit }`
          },
          name: `${ this.$t('cameraTesterComponent.received') }`
        }
      ];

      this.motionSeries = [
        {
          data: response.reports.motionDetectionReports.map(o => o.totalFramesReceived),
          color: "rgb(99, 100, 30)",
          line: {
            style: "smooth"
          },
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # ${ this.$t('cameraTesterComponent.frames') }`
          },
          axis: "Count",
          name: this.$t('cameraTesterComponent.totalFrames')
        }
      ];

      this.motionPercent = [
        {
          data: response.reports.motionDetectionReports.map(o => o.totalFramesPercentage),
          color: "rgb(99, 0, 30)",
          opacity: .666,
          line: {
            style: "smooth"
          },
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # % of ${ this.$t('cameraTesterComponent.frames') }`
          },
          axis: "Percent",
          name: this.$t('cameraTesterComponent.totalFramesPercentage')
        }
      ];

      this.recordingSeries = [
        {
          data: response.reports.recordingReports.map(o => o.totalFramesReceived),
          color: "rgb(99, 100, 30)",
          line: {
            style: "smooth"
          },
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # ${ this.$t('cameraTesterComponent.frames') }`
          },
          axis: "Count",
          name: this.$t('cameraTesterComponent.totalFrames')
        }
      ];

      this.recordingPercent = [
        {
          data: response.reports.recordingReports.map(o => o.totalFramesPercentage),
          color: "rgb(99, 0, 30)",
          opacity: .666,
          line: {
            style: "smooth"
          },
          tooltip: {
            visible: true,
            template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ this.$t('cameraTesterComponent.received') } #= value # % of ${ this.$t('cameraTesterComponent.frames') }`
          },
          axis: "Percent",
          name: this.$t('cameraTesterComponent.totalFramesPercentage')
        }
      ];

      this.motionFrameReportTypes = [];
      response.reports.motionDetectionReports.forEach(motionDetectionReport => {
        motionDetectionReport.frameReports.forEach(frameReport => {
          if (this.motionFrameReportTypes.includes(frameReport.type) == false) {
            this.motionFrameReportTypes.push(frameReport.type);
          }
        });
      });
      this.motionFrameReportTypesData = [];
      this.motionFrameReportTypes.forEach(() => {
        this.motionFrameReportTypesData.push([]);
      });
      response.reports.motionDetectionReports.forEach(motionDetectionReport => {
        this.motionFrameReportTypes.forEach((type, index) => {
          var found = motionDetectionReport.frameReports.find(o => o.type == type);
          if (found) {
            this.motionFrameReportTypesData[index].push(found.count);
          } else {
            this.motionFrameReportTypesData[index].push(0);
          }
        });
      });
      this.motionFrameReportTypes.forEach((type, index) => {
        this.motionSeries.push(
          {
            data: this.motionFrameReportTypesData[index],
            color: this.colors[index],
            line: {
              style: "smooth"
            },
            opacity: .666,
            axis: "Count",
            tooltip: {
              visible: true,
              template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ type }s #= value #`
            },
            name: `${ type }s`
          }
        );
      });

      this.recordingFrameReportTypes = [];
      response.reports.recordingReports.forEach(recordingReport => {
        recordingReport.frameReports.forEach(frameReport => {
          if (this.recordingFrameReportTypes.includes(frameReport.type) == false) {
            this.recordingFrameReportTypes.push(frameReport.type);
          }
        });
      });
      this.recordingFrameReportTypesData = [];
      this.recordingFrameReportTypes.forEach(() => {
        this.recordingFrameReportTypesData.push([]);
      });
      response.reports.recordingReports.forEach(recordingReport => {
        this.recordingFrameReportTypes.forEach((type, index) => {
          var found = recordingReport.frameReports.find(o => o.type == type);
          if (found) {
            this.recordingFrameReportTypesData[index].push(found.count);
          } else {
            this.recordingFrameReportTypesData[index].push(0);
          }
        });
      });
      this.recordingFrameReportTypes.forEach((type, index) => {
        this.recordingSeries.push(
          {
            data: this.recordingFrameReportTypesData[index],
            color: this.colors[index],
            line: {
              style: "smooth"
            },
            opacity: .666,
            axis: "Count",
            tooltip: {
              visible: true,
              template: `${ this.$t('cameraTesterComponent.date') }: #= category # <br/> ${ type }s #= value #`
            },
            name: `${ type }s`
          }
        );
      });
    },
//#endregion

//#region Cancel Axios Requests
    cancelGetRequest () {
      if (this.cancelGetSource) {
        this.cancelGetSource.cancel();
      }
    },
    cancelOrderRequest () {
      if (this.cancelOrderSource) {
        this.cancelOrderSource.cancel();
      }
    }
//#endregion
  }
}
</script>

<style scoped>
.lastElementNoBorder {
  margin-bottom: 0;
}
.lastElementNoBorder tr:last-child td {
  border-bottom: none;
}
.miniChart .k-chart {
  height: 200px;
}
.scrollDiv {
  max-height: 300px;
}
</style>
